.kontakt {
	padding-top: 180px;
	min-height: 100vh;

	h1 {
		margin-top: 20px !important;
	}

	.info {
		margin-top: 150px;
		padding-left: 50px;
		padding-right: 50px;

		h2 {
			text-align: center;
		}
	}

	h3 {
		text-align: center;
	}

	.social-media {
		justify-content: center;

		img {
			margin: 20px;
		}
	}
}


.pank {
	text-align: center;
	margin-top: 70px;

	h3 {
		margin-left: 0;
		height: 28px;
	}
}

.ui.button.copy-button {
	color:black!important;
	background-color: transparent!important;
}

.with-button {
	h3 {
		margin: 0;
	}
	display: flex;
	justify-content: center;
}

@media only screen and (max-width: 767px) {
	.kontakt {
		padding-top: 100px;
	}
}