.heading {
	z-index: 500;
	position: absolute;
	display: flex;
	justify-content: space-between;
	padding: 5px 40px;
	height: 120px;
	width: 100%;
	min-width: 320px;
	background-color: white;
	-webkit-box-shadow: 1px 14px 47px -13px rgba(0, 0, 0, 0.47);
	box-shadow: 1px 14px 47px -13px rgba(0, 0, 0, 0.47);

	h2 {
		margin-top: auto;
		margin-bottom: auto;
	}

	span {
		margin-top: auto;
		margin-bottom: auto;
		font-size: 1.71428571rem;
		line-height: 1.28571429em;
		font-weight: 700;
	}

	h2:hover,
	span:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}

.width-40 {
	width: 40%;
	justify-content: space-around;
}

.logo-container {
	position: relative;
	display: flex;
	width: 20%;
	justify-content: center;
	z-index: 501;

	a {
		position: relative;
		z-index: 501;
		width: 93px;

		#navbar-logo {
			position: relative;
			display: block;
			height: 100%;
			transition: 0.2s ease;
			z-index: 501;
			margin: auto;
		}
	}
}

#navbar-logo:hover {
	cursor: pointer;
	transform: scale(1.08);
}

#burger {
	display: none;
	position: absolute;
	height: 23px;
	cursor: pointer;
	right: 30px;
	top: 50%;
	transform: translateY(-50%);
	transition: 0.2 ease;
	z-index: 505;
}

#burger:hover {
	filter: invert(40%)
}

#burger span {
	display: block;
	width: 33px;
	height: 4px;
	margin-bottom: 5px;
	position: relative;

	background: #000000;

	z-index: 1;

	transform-origin: 4px 0px;
	transition: 0.2 ease;
}

.alert {
	display: inline;
	color: #990000;
}

// Mobile
@media only screen and (max-width: 767px) {

	.heading {
		height: 100px;
		justify-content: center;

		.logo-container {
			width: 100%;
		}
	}

	.width-40 {
		display: none
	}

	#burger {
		display: block
	}
}