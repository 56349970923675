.section-competition {
	padding-top: 120px;
	min-height: 600px;
}

.upcoming-banner {
	display: block;
	height: 80px;
	border: 7px solid black;
	background-color: rgb(208, 37, 37);

	h1 {
		margin-top: 10px !important;
		font-size: 35px;
		text-align: center;
	}
}

.text-center {
	text-align: center;
}

.past-banner {
	display: block;
	height: 80px;
	border: 7px solid black;
	background-color: rgb(140, 140, 140);
	margin-top: 20px;

	h2 {
		margin-top: 15px !important;
		font-size: 30px;
		text-align: center;
	}
}

.results {
	margin-top: 10px !important;
	max-width: 400px;
	margin: auto;
	border: 5px solid maroon;

	h2 {
		color: maroon;
		word-break: break-word;
	}

	h2:hover {
		text-decoration: underline;
		opacity: 0.7;
	}
}

.photos {
	margin-top: 15px !important;
	max-width: 400px;
	margin: auto;
	border: 5px solid black;

	h2 {
		color: black;
		word-break: break-word;
	}

	h2:hover {
		text-decoration: underline;
		opacity: 0.7;
	}
}

.nimekiri {
	display: grid;
	justify-content: center;

	.item {
		cursor: pointer;

	}
}

.hover-cursor {
	cursor: pointer;
}

.media {
	h3 {
		margin: 0
	}
}

.ui.list,
.ui.ordered.list {
	margin: auto;

	.item {

		h2 {
			display: inline-block;
			margin: auto 10px auto 0;
		}

		h3 {
			display: inline-block;
			line-height: 1.28571429em;
		}
	}

	.item:hover {

		h2,
		h3 {
			text-decoration: underline;
		}
	}
}

.media-headline {
	border-bottom: 3px solid black;
	width: 80%;
	margin: auto;
}

.media {
	margin-bottom: 15px;
}

.border-3px-80percent {
	border-top: 3px solid black;
	width: 80%;
	margin: auto;
}

.competition-item {
	padding: 24px 0;

	.competition-heading {
		width: 100%;
		max-width: 400px;
	}

	.competition-img {
		max-width: 400px;
		width: auto;
	}
}

//Competition component part
.competition {
	padding-top: 120px;

	h1 {
		text-align: center;
		margin-top: 20px !important;
		border: 7px solid black;
		padding: 7px;
	}

	.social-media-event:hover {
		cursor: pointer;
		color: gray;
		text-decoration: underline;
	}

	.social-media-event {
		display: flex;
		justify-content: center;

		a {
			display: inline-block;
			width: 200px;
			height: 100%;
		}

		h3 {
			display: inline;
			margin: auto 10px auto 0;
		}

		img {
			width: 30px;
			height: 30px;
		}
	}
}

.content {
	margin-top: 20px;
	margin-bottom: 50px;
}

.popup .content {
	margin: auto !important;
}

.info-div {
	margin-top: 40px !important;
	padding-left: 50px;
	padding-right: 50px;

	h2 {
		text-align: center;
	}

	.ui.list>.item {
		font-family: 'Chakra Petch', sans-serif !important;
		font-size: 20px;

		h3 {
			margin-left: 7px;
		}
	}
}

.inline {
	display: inline;
}

.ui.form {
	font-family: 'Chakra Petch', sans-serif !important;

	.field>label {
		font-size: 16px;
	}
}

.ui.form .ui.button {
	display: block;
	margin: auto;
}

.sponsors {
	h2 {
		font-size: 25px;
		text-align: center;
	}

	.sponsor-list {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		.sponsor {
			display: block;
			margin: 15px;
			width: 170px;

			img {
				margin-top: auto;
				width: 170px;
			}
		}
	}
}

.media div.ui.list {
	margin-top: 10px;
}

.info-div {
	width: 75%;
	margin: auto;
}

.live {
	background-color: black;

	iframe {
		display: block;
		margin: auto;
		margin-top: 30px;
	}
}

.color-maroon {
	color: maroon
}

.framed {
	margin: 40px 0;
	padding: 15px;
	border: 7px solid black;
}

@media only screen and (max-width: 767px) {
	.section-competition {
		padding-top: 100px !important;

		h1 {
			font-size: 25px;
		}
	}

	.live iframe {
		width: 100%;
	}

	.past-banner {
		h2 {
			font-size: 23px;
		}
	}

	.ui.list,
	.ui.ordered.list {
		width: 90%;

		.item {
			flex-wrap: wrap;
			justify-content: center;
		}

		h2 {
			width: 100%;
			text-align: center;
		}

		h3 {
			margin: 0;
		}

		h2,
		h3 {
			text-decoration: underline;
		}
	}


	.info-div .ui.list,
	.info-div .ui.ordered.list {
		h3 {
			text-decoration: none !important;
		}

	}

	.competition {
		padding-top: 100px;
	}

	.social-media-event {
		text-decoration: underline;
	}

	.info-div {
		width: 95%;
		padding-left: 5px;
		padding-right: 5px;
	}
}