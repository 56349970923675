.intro {
	position: relative;
	overflow-x: hidden;
	height: 100vh;
	min-height: 800px;
	padding-top: 120px;

	.background {
		position: absolute;
		height: calc(100% - 120px);
		width: calc(100% + 12px);
		left: -6px;
		background-image: url("../../imgs/fotod/kiilakas.jpg");
		background-size: cover;
		background-position: center;
		filter: blur(3px);
		-webkit-filter: blur(3px);
		transform-style: preserve-3d;
	}

	div {
		filter: blur(0px);
		-webkit-filter: blur(0px);
	}
}

.area {
	position: relative;
	height: 100%;
	transform-style: preserve-3d;

	.container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform-style: preserve-3d;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 600px;
		z-index: 2;

		.phrase {
			margin-bottom: 20px;

			h1 {
				text-align: center;
				font-size: 2.5rem;
				-webkit-text-stroke-width: 0.8px;
				-webkit-text-stroke-color: white;
			}
		}

		.circle-card {
			height: 400px;
			width: 400px;
			display: inline-block;
			border: 1px white;
			background-color: white;
			border-radius: 1000px;

			transition: 0.2s ease;
			display: block;
			margin: auto;

			-webkit-box-shadow: 5px 5px 14px 5px rgba(0, 0, 0, 0.73);
			box-shadow: 5px 5px 14px 5px rgba(0, 0, 0, 0.73);

			transform-style: preserve-3d;

			#logo {
				background-image: url("../../imgs/Rammukoobas_logo.jpg");
				background-position: center;
				background-origin: content-box;
				background-size: contain;
				background-repeat: no-repeat;
				background-position-x: -8px;
				height: 100%;
				width: 100%;
				transition: all 0.1s ease-out;
				z-index: 3;
				transform-style: preserve-3d;
				border-radius: 100%;

				padding: 24px;

				border: 10px solid black;
			}
		}
	}

}

// Mobile
@media only screen and (max-width: 767px) {
	.intro {
		padding-top: 100px;

		.background {
			height: calc(100% - 100px);
		}
	}
}


@media only screen and (max-width: 600px) {
	.area {
		.container {
			top: 60%;
			left: 50%;
			transform: translate(-50%, -60%);
		}

		div.circle-card {
			height: 250px !important;
			width: 250px !important;
		}
	}

	#logo {
		padding: 8px !important;
		background-position-x: -4px !important;
	}
}