.klubist {
	padding-top: 120px;
	min-height: 100vh;

	h1 {
		margin-top: 20px !important;
	}

	.margin-top-50 {
		margin-top: 50px !important;
	}
}

.joon {
	border-bottom: 5px solid black;
	width: 70%;
	margin: 5px auto 5px auto;
}

.joon.vaiksem {
	width: 50%;
}

.joon.veelvaiksem {
	width: 40%;
}

.banner {
	width: 100%;
	height: 500px;
	background-image: url("../../imgs/fotod/sumin.jpg");
	background-size: cover;
	background-position: center;
}

.tekst {
	margin-top: 30px !important;
	width: 70%;
	margin: auto;
	padding-left: 50px;
	padding-right: 50px;
}

.center {
	text-align: center;
}



.sponsorid {
	.sponsori-pildid {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		.logo {
			display: block;
			margin: 10px;

			img {
				margin: auto
			}
		}

		img {
			margin: auto;
			width: 200px;
		}

		a {
			color: black;
			text-decoration: underline;
		}

		a:hover {
			color: gray;
		}
	}
}

@media only screen and (max-width: 767px) {
	.klubist {
		padding-top: 100px;
	}

	.tekst {
		padding-left: 5px;
		padding-right: 5px;
		width: 100%;
	}
}